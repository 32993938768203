import { Close } from "@mui/icons-material";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  Grid,
  Chip,
} from "@mui/material";
import React from "react";

const ProductZoomDialog = (props) => {
  const { open, onClose, product, imageUrl } = props;

  const showSize = product && !product.more;

  if (!open) return null;

  const getLabel = () => {
    if (product.price) {
      return product.price + " บาท";
    }
    return "Size: " + (+product.size > 0 ? product.size : "-");
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            {product.id_product} - {product.model}
          </Grid>
          <Grid item>
            {showSize ? (
              <Chip size="small" color="primary" label={getLabel()} />
            ) : null}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <img
          style={{ minHeight: 600, width: "100%" }}
          src={imageUrl + "&preview=false"}
          alt={product.id_product}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button color="error" onClick={() => onClose()} startIcon={<Close />}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductZoomDialog;
