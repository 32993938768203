import { Card, Grid, Typography, Box, Chip } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import axios from "../utils/axios";
import { toast } from "react-toastify";
import ProductZoomDialog from "./ProductZoomDialog";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import useAppState from "../hooks/useAppState";
import useWindowDimensions from "../hooks/useWindowDimension";

const itemWidth = 420;

const ProductList = () => {
  const { code, weightId, model } = useParams();
  const { width } = useWindowDimensions();
  const smallScreen = useMemo(() => width < 900, [width]);

  const navigate = useNavigate();

  const { items, setItems, products } = useAppState();
  const product = products.find((p) => p.product_code == code);
  const weight = product?.weights.find((w) => w.id == weightId);

  const [loading, setLoading] = useState(false);

  const [displayItems, setDisplayItems] = useState([...items]);

  const customWidth = useMemo(
    () =>
      smallScreen
        ? { minWidth: itemWidth, maxWidth: itemWidth }
        : { width: "100%" },
    [smallScreen]
  );

  useEffect(() => {
    if (product && weight) {
      setLoading(() => true);
      setItems(() => []);

      const url =
        product.product === "ทองหุ้ม"
          ? `/api/models?product=${product.product}&name=${weight.name}`
          : `/api/models?product=${product.product}&start=${weight.start}&end=${weight.end}`;

      axios
        .get(url)
        .then(({ data }) => {
          setItems(() => data);
          setLoading(() => false);
        })
        .catch((err) => {
          toast.error(err.response.data.message, { position: "top-center" });
          setItems(() => []);
          setLoading(() => false);
        });
    }
  }, [code, weight, product]);

  const [openZoom, setOpenZoom] = useState(false);
  const [zoomProduct, setZoomProduct] = useState(null);
  const [zoomUrl, setZoomUrl] = useState("");

  useEffect(() => {
    if (model) {
      setDisplayItems((prev) => {
        const clone = [...prev];
        return clone.filter((c) => c.model === model);
      });
    } else {
      setDisplayItems(items);
    }
  }, [model, items]);

  if (loading) {
    return (
      <div
        style={{
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading type={"spinningBubbles"} color="red" />
      </div>
    );
  }

  if (!product || !weight) return null;

  const imageHeight = 250;

  const getLabel = (productItem) => {
    if (productItem.price) {
      return productItem.price + " บาท";
    }
    return "Size: " + (+productItem.size > 0 ? productItem.size : "-");
  };

  const renderItem = (productItem) => {
    return (
      <Card
        variant="elevation"
        sx={{
          minHeight: 400,
          maxHeight: 400,
          padding: 3,
          ...customWidth,
        }}
      >
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="h6">
              {productItem.id_product} - {productItem.model}
            </Typography>
          </Grid>
          <Grid item>
            <Chip size="small" color="primary" label={getLabel(productItem)} />
          </Grid>
        </Grid>
        <Box mt={2} />
        <Grid item>
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenZoom(() => true);
              setZoomProduct(() => productItem);
              setZoomUrl(
                () => `/api/images?idProduct=${productItem.id_product}`
              );
            }}
            src={`/api/images?idProduct=${productItem.id_product}`}
            alt={productItem.id_product}
            width="100%"
            height={imageHeight}
          />
        </Grid>
        <Box mt={2} />
        <Typography>{productItem.description || "-"}</Typography>
      </Card>
    );
  };

  return (
    <div style={{ paddingTop: 40 }}>
      <ProductZoomDialog
        open={openZoom}
        product={zoomProduct}
        onClose={() => setOpenZoom(() => false)}
        imageUrl={zoomUrl}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {product.product}
            {model || ""} - {weight.name}
          </Typography>
        </Grid>
        {displayItems.map((p, index) => {
          // มีสินค้าในกลุ่มเดียวกันมากกว่า 1 รายการ
          if (p.items.length > 1) {
            if (!model) {
              const firstItem = p.items[0];
              return (
                <Grid
                  item
                  xl={3}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={index}
                  container
                  justifyContent="center"
                >
                  <Card
                    variant="elevation"
                    sx={{
                      minHeight: 400,
                      maxHeight: 400,
                      padding: 3,
                      ...customWidth,
                    }}
                  >
                    <Grid container justifyContent={"space-between"}>
                      <Grid item>
                        <Typography variant="h5">{p.model}</Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          onClick={() => {
                            navigate("more/" + firstItem.model);
                          }}
                          size="small"
                          color="success"
                          label={
                            "ดูเพิ่มอีก " +
                            p.items.length.toLocaleString() +
                            " รายการ"
                          }
                        />
                      </Grid>
                    </Grid>
                    <Box mt={2} />
                    <Grid item>
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOpenZoom(() => true);
                          setZoomProduct(() => ({ ...firstItem, more: true }));
                          setZoomUrl(
                            () =>
                              `/api/images?idProduct=${firstItem.id_product}`
                          );
                        }}
                        src={`/api/images?idProduct=${firstItem.id_product}`}
                        alt={p.model}
                        width="100%"
                        height={imageHeight}
                      />
                    </Grid>
                    <Box mt={2} />
                    <Typography>{firstItem.description || "-"}</Typography>
                  </Card>
                </Grid>
              );
            } else {
              return p.items.map((i) => (
                <Grid
                  item
                  xl={3}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={i.id_product}
                  justifyContent="center"
                  container
                >
                  {renderItem(i)}
                </Grid>
              ));
            }
          } else {
            // ถ้ามีแค่ 1 ตัว
            const firstItem = p.items[0];
            return (
              <Grid
                container
                justifyContent="center"
                item
                xl={3}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                key={firstItem.id_product}
              >
                {renderItem(firstItem)}
              </Grid>
            );
          }
        })}
      </Grid>
    </div>
  );
};

export default ProductList;
