import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "h1.jpg",
    thumbnail: "h1.jpg",
  },
  {
    original: "h2.jpg",
    thumbnail: "h2.jpg",
  },
  {
    original: "Home3.jpg",
    thumbnail: "Home3.jpg",
  },
  {
    original: "Home4.jpg",
    thumbnail: "Home4.jpg",
  },
  {
    original: "Home5.jpg",
    thumbnail: "Home5.jpg",
  },
  {
  original: "Home6.jpg",
  thumbnail: "Home6.jpg",
  },
  //{
  //  original: "Home6.jpg",
  //  thumbnail: "Home6.jpg",
  //},
  // {
  //   original: "Home7.jpg",
  //   thumbnail: "Home7.jpg",
  // },
  // {
  //   original: "Home8.jpg",
  //   thumbnail: "Home8.jpg",
  // },
];

const HomePage = () => {
  return (
    <>
      <ImageGallery items={images} autoPlay slideInterval={6000} />
    </>
  );
};

export default HomePage;
