import React from "react";
import { Link, useParams } from "react-router-dom";
import { Typography, Grid, Box, Card } from "@mui/material";
import useAppState from "../hooks/useAppState";

const ProductWeightPage = () => {
  const { code } = useParams();
  const { products } = useAppState();
  const product = products.find((p) => p.product_code == code);

  if (!product) {
    return null;
  }

  return (
    <div>
      <img
        style={{ width: 140, height: 140 }}
        src={process.env.PUBLIC_URL + "/" + product.product_code + ".jpg"}
        alt={product.product}
      />
      <Box mt={3} />
      <Typography variant="h4">{product.product}</Typography>

      <Box mt={6} />
      <Grid container spacing={4}>
        {product.weights.map((w) => {
          return (
            <Grid key={w.id} item xs={12} lg={2}>
              <Link to={"weights/" + w.id}>
                <Card sx={{ minHeight: 200 }} variant="outlined">
                  <Box mt={4} />
                  <Typography variant="h5">{w.name}</Typography>
                  <Box mt={2} />
                  <img
                    style={{ width: 60, height: 60 }}
                    src={
                      process.env.PUBLIC_URL +
                      "/" +
                      product.product_code +
                      ".jpg"
                    }
                    alt={product.product}
                  />
                </Card>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ProductWeightPage;
