import { useEffect } from "react";
import axios from "../utils/axios";
import useAuthState from "../hooks/useAuthState";
import { useNavigate, useLocation } from "react-router-dom";

const PrivateComponent = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const callbackUrl = location.pathname ?? "";

  const { setName, setChecking, setIsLoggedIn, checking, isLoggedIn, name } =
    useAuthState();

  useEffect(() => {
    axios
      .get("/api/auth/me")
      .then((response) => {
        const {
          data: { name },
        } = response;
        setName(name);
        setIsLoggedIn(true);
        console.log("private component get success name");
      })
      .catch((err) => {
        setName(null);
        setIsLoggedIn(false);
        navigate("/login?callbackUrl=" + callbackUrl, { replace: true });
      })
      .finally(() => {
        setChecking(false);
      });
  }, [callbackUrl, navigate, setChecking, setIsLoggedIn, setName]);

  return !checking && isLoggedIn && name ? children : null;
};

export default PrivateComponent;
